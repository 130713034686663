import { createApp } from 'vue'
import VueMobileDetection from "vue-mobile-detection";
import App from './App.vue'

import router from './router'
import './registerServiceWorker'

createApp(App)
    .use(router)
    .use(VueMobileDetection)
    .mount('#app');
