import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/CookiesView.vue')
  },
  {
    path: '/invalid',
    name: 'invalid',
    component: () => import(/* webpackChunkName: "invalid" */ '../views/InvalidView.vue')
  },
  {
    path: '/copy',
    name: 'copy',
    component: () => import(/* webpackChunkName: "invalid" */ '../views/CopyView.vue')
  },
  {
    path: '/:msgid/:uid?',
    name: 'message',
    component: () => import(/* webpackChunkName: "message" */ '../views/MessageView.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "message" */ '../views/DemoButton.vue')
  },
  {
    path: '/subscribed',
    name: 'subscribed',
    component: () => import(/* webpackChunkName: "message" */ '../views/Subscribed.vue')
  },
  {
    path: '/cancelled',
    name: 'cancelled',
    component: () => import(/* webpackChunkName: "message" */ '../views/Cancelled.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
