<template>
  <div class="home">
    <head></head>
    <iframe
      id="webflow-iframe"
      frameborder="0"
      scrolling="no"
      seamless="seamless"
      style="position: absolute; top: 0; left: 0; width: 100%; z-index: 0"
      width="100%"
      height="4000px"
      :srcdoc="body"
      title="webflow embed"
    ></iframe>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      body: "",
    };
  },
  async mounted() {
    if (window.location.href.includes('#pricing')) {
        window.location.href = 'https://www.notion.so/refemail/Reference-email-b6047a506a3b42bcb0c680e960fdc19e?pvs=4#0e0ac675642044f7a758b779a09ac902';
      }

    let calculateScrollbarWidth = () => {
      let w = window.innerWidth - document.documentElement.offsetWidth;
      document.body.style.setProperty('--scrollbar-width', Math.ceil(w) + 'px');
    }

    calculateScrollbarWidth();
    let lastKnownHeight = 0;
    let watchingIframe = false;
    let handleSubMessageWindow = (e) => {
      let webflowIframe = document.querySelector('#webflow-iframe');

      if (webflowIframe && e.source == webflowIframe.contentWindow) {
        if ('iframeLink' in e.data) {
          location.href = e.data.iframeLink;
        }
        if ('iframeHeight' in e.data) {
          webflowIframe.style.height = e.data.iframeHeight + 'px';
          lastKnownHeight = e.data.iframeHeight;
          webflowIframe.contentWindow.postMessage({ iframeHeight: e.data.iframeHeight }, '*');

          if (!watchingIframe) {
            watchingIframe = true;
            let frame = () => {
              requestAnimationFrame(frame);
              if (
                webflowIframe &&
                parseInt(webflowIframe.style.height) != lastKnownHeight + 'px'
              ) {
                webflowIframe.style.height = lastKnownHeight + 'px';
              }
            }
            frame();
          }
        }
      }
    }
    window.addEventListener('message', handleSubMessageWindow);
    // this.$router.push("/about")
    await fetch("https://ref-email-webflow-proxy.dnbr.workers.dev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          query {
            getWebflowEmbed
          }
        `,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        document.querySelector("head").innerHTML += res.head;
        const innerJss = `<script>
(() => {
	document.body.style.overflow = 'hidden';
	window.addEventListener("click", function (e) {
		console.log('click', e.target.tagName);
		let closest = e.target.closest('a');
		if (closest) {
			e.preventDefault();
			e.stopPropagation();
			window.parent.postMessage({
				iframeLink: closest.href
			}, '*');
		}
	});
	
	let lastHeight = 0;
	let ack = false;
	window.addEventListener('message', (e) => {
		if (e.data && e.data.iframeHeight == lastHeight) {
			ack = true;
		}
	});
	function bubbleChange() {
		document.body.style.height = '0px';
		let height = 0;
		for (let i = 0; i < document.body.children.length; i++) {
			let child = document.body.children[i];
			height += child.offsetHeight;
		}
		console.log('height', height);
		if (height !== lastHeight) {
			lastHeight = height;
			ack = false;
		}

		if (!ack) {
			window.parent.postMessage({
				iframeHeight: height
			}, '*');
		}
	}
	function frame() {
		requestAnimationFrame(frame);
		bubbleChange();
	}
	requestAnimationFrame(frame);
})();
`;

        this.body = res.body + innerJss + `< /script>`.split(" ").join("");

      });
    
  }
}
</script>
