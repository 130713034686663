<template>
  <nav id="nav">
    <router-link to="/cookies">Cookies</router-link>
    <router-link to="/about">About</router-link>
    <a href="https://dnbr.to/docs/dunbar_privacy_policy.pdf">Privacy</a>
  </nav>
  <router-view/>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'App',
  data() {
    return {
    };
  },
  mounted() {
    if (typeof window !== "undefined") {
      if (location.host === "ref.email" && !location.pathname.startsWith("/rfc822msgid")) {
        location.href = location.href.replace("https://ref.email", "https://reference.email");
      }
    }
  }
}
</script>


<style>
html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;

}

nav {
  text-align: right;
  position: fixed;
  left: 0px; right: 0px;
  top: 0px;
  padding: 30px;
  z-index: 2;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
  margin: 0px 1rem;
}

nav a.router-link-exact-active {
  color: dodgerblue;
}

.page {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
</style>
